import React from "react";
import "./App.css";
import LandingPage from "./landingpage";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return <LandingPage></LandingPage>;
  }
}

export default App;
